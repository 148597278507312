import React from 'react';
import styled from 'styled-components';

const ModalBackdrop = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 100000;
`;

const ModalContent = styled.div`
  background: white;
  padding: 0 5px 10px 10px;
  border-radius: 5px;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.3);
  max-width: 991px;
  width: 100%;
  height: 90%;
  z-index: 100001;

  @media only screen and (max-width: 991px) {
    height: 100vh;
  }
`;

const ModalHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 37px !important;
`;

const ModalTitle = styled.h2`
  margin: 0;
  font-size: 24px !important;
  font-family: CocogoosePro-Light;
`;

const CloseButton = styled.button`
  background: transparent;
  border: none;
  cursor: pointer;
  font-size: 30px;
  margin-left: auto;
  color: gray !important;
  padding: 0 !important;

  &:hover {
    background-color: transparent !important;
  }
`;

const Modal = ({ show, title, children, onClose }) => {
  if (!show) {
    return null;
  }

  return (
    <ModalBackdrop onClick={onClose}>
      <ModalContent onClick={e => e.stopPropagation()}>
        <ModalHeader>
          {title && <ModalTitle>{title}</ModalTitle>}
          <CloseButton onClick={onClose}>&times;</CloseButton>
        </ModalHeader>
        <div style={{height: 'calc(100% - 37px)'}}>
          {children}
        </div>
      </ModalContent>
    </ModalBackdrop>
  );
};

export default Modal;
